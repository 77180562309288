import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiGateway } from '../../api/ApiGateway.service';
import { CurrencyModel } from './currency.model';

@Injectable()
export class CurrencyApi {
  private endpoint = 'currencies/';

  public constructor(private gateWay: ApiGateway) {}

  public fetch(): Observable<{ Currency: CurrencyModel }[]> {
    return this.gateWay.get(this.endpoint);
  }
}
